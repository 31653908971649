<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fade } from "svelte/transition";

  const dispatch = createEventDispatcher();

  let i = 0;
  let loaded = false;

  let bootText = document.querySelectorAll(".boot-line");

  const loadLines = () => {
    if (i < bootText.length) {
      bootText[i].hidden = false;
      i++;
      setTimeout(loadLines, 500);
    } else {
      setTimeout(() => {
        if (loaded) {
          dispatch("loaded");
        }
      }, 2000);
    }
  };

  onMount(() => {
    bootText = document.querySelectorAll(".boot-line");
    window.addEventListener("load", () => {
      loaded = true;
    });
    loadLines();
  });
</script>

<div class="wrapper" out:fade={{ duration: 600 }}>
  <ul class="boot-text">
    <li class="boot-line" hidden>BOOTING KUROI.OS - v1.0.2</li>
    <li class="boot-line" hidden>DEVELOPED BY JESSICA - KUROI DEV - 2023</li>
    <li class="boot-line" hidden>&nbsp;</li>
    <li class="boot-line" hidden>LOADING A WEB EXPERIMENT LAB</li>
    <li class="boot-line" hidden>CODING IMMERSIVE AND UNIQUE STUFF</li>
    <li class="boot-line" hidden>&nbsp;</li>
    <li class="boot-line" hidden>
      <h1 class="title">Kuroi.OS</h1>
    </li>
    <li class="boot-line" hidden>KUROI.OS IS LOADING.</li>
    <li class="boot-line" hidden>
      PLEASE, WAIT<span class="text-pipe">_</span>
    </li>
  </ul>
  <span class="subtitle" hidden />
</div>

<style>
  .boot-text {
    list-style: none;
    padding-left: 0;
  }

  .boot-text li {
    margin-bottom: 10px;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    background-color: black;
    padding: 10px;
    font-family: "Perfect DOS";
    font-size: 1.2em;
    color: var(--theme-color);
    text-shadow: 0px 0px 8px var(--theme-color);
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
  }

  .wrapper::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        rgba(18, 16, 16, 0) 50%,
        rgba(0, 0, 0, 0.25) 50%
      ),
      linear-gradient(
        90deg,
        rgba(255, 0, 0, 0.06),
        rgba(0, 255, 0, 0.02),
        rgba(0, 0, 255, 0.06)
      );
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }

  h1 {
    font-size: clamp(3.5em, 3.5em + 1vw, 6em);
    font-family: "Display";
    margin-top: 0;
  }

  .subtitle {
    font-family: "Perfect DOS";
    font-size: 2em;
  }

  .text-pipe {
    animation: blink 1s step-start 0s infinite;
    font-family: "Perfect DOS";
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
</style>

import { readable, writable } from "svelte/store";
import BrowserHome from "../Content/Browser/BrowserHome.svelte";
import Browser404 from "../Content/Browser/Browser404.svelte";
import WebXP from "../Content/Browser/WebXP/WebXP.svelte";
import Contact from "../Content/Browser/Contact.svelte";
import Blog from "../Content/Browser/Blog.svelte";
import WebXPFrame from "../Content/Browser/WebXP/WebXPFrame.svelte";

export const pages = readable([
  {
    key: "page_home",
    component: BrowserHome,
    url: "",
  },
  {
    key: "page_404",
    component: Browser404,
    url: null,
  },
  {
    key: "page_webxp",
    component: WebXP,
    url: "webxp.kr",
  },
  {
    key: "page_contact",
    component: Contact,
    url: "kuromail.kr",
  },
  {
    key: "page_blog",
    component: Blog,
    url: "blog.kr",
    src: "https://blog.kuroi.com.br",
  },
  {
    key: "page_webxp_github_explorer",
    component: WebXPFrame,
    url: "webxp.kr/github-explorer",
    src: "https://github-explorer-git-main-ikuroidev.vercel.app",
  },
  {
    key: "page_webxp_signup",
    component: WebXPFrame,
    url: "webxp.kr/signup-cards/",
    src: "https://ikuroidev.github.io/signup-cards/",
  },
  {
    key: "page_webxp_time_dashboard",
    component: WebXPFrame,
    url: "webxp.kr/time-dashboard/",
    src: "https://ikuroidev.github.io/frontendmentor-timedashboard-html-css/",
  },
]);

<script>
  import * as THREE from "three";
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
  import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
  import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
  import { onMount } from "svelte";

  import { debounce } from "lodash";
  import DescriptionBox from "../../UI/DescriptionBox.svelte";

  // Define canvas object
  let canvas;

  // Define window size
  const sizes = {
    width: 0,
    height: 0,
  };

  onMount(() => {
    canvas = document.querySelector(".webgl");

    const parentWindow =
      document.querySelector(".canvas-wrapper").parentElement.parentElement
        .parentElement;

    const contentWindow =
      document.querySelector(".canvas-wrapper").parentElement.parentElement;

    sizes.width = contentWindow.innerWidth;
    sizes.height = contentWindow.innerHeight;

    const windowSizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        handleResize();
      });
    });
    windowSizeObserver.observe(contentWindow);

    const handleResize = debounce(() => {
      sizes.width = contentWindow.clientWidth;
      sizes.height = contentWindow.clientHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
    }, 100);

    // Scene
    const scene = new THREE.Scene();

    // textures
    const textureLoader = new THREE.TextureLoader();

    const matcapTexture = textureLoader.load("/img/matcaps/4.png");

    // Fonts
    const fontLoader = new FontLoader();

    fontLoader.load("/fonts/helvetiker_regular.typeface.json", (font) => {
      const textGeometry = new TextGeometry("Hello World", {
        font: font,
        size: 0.5,
        height: 0.2,
        curveSegments: 5,
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 4,
      });

      textGeometry.center();

      const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
      const text = new THREE.Mesh(textGeometry, material);

      scene.add(text);

      const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);

      for (let i = 0; i < 200; i++) {
        const donut = new THREE.Mesh(donutGeometry, material);

        donut.position.x = (Math.random() - 0.5) * 10;
        donut.position.y = (Math.random() - 0.5) * 10;
        donut.position.z = (Math.random() - 0.5) * 10;

        donut.rotation.x = Math.random() * Math.PI;
        donut.rotation.y = Math.random() * Math.PI;

        const scale = Math.random() + 0.01;
        donut.scale.x = scale;
        donut.scale.z = scale;
        donut.scale.y = scale;

        scene.add(donut);
      }
    });

    // Camera
    const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height);
    camera.position.z = 4;
    scene.add(camera);

    // Update render on screen resize
    window.addEventListener("resize", () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
    });

    // Renderer
    const renderer = new THREE.WebGLRenderer({ canvas: canvas });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const controls = new OrbitControls(camera, canvas);
    controls.autoRotate = true;
    controls.autoRotateSpeed = 0.5;
    // Loop function
    const clock = new THREE.Clock();

    let tick = () => {
      //Get delta time
      const elapsedTime = clock.getElapsedTime();

      // Update Objects
      controls.update();

      // Update renderer
      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();
  });
</script>

<div class="canvas-wrapper">
  <canvas class="webgl" />
</div>

<style>
  .canvas-wrapper {
    background: black;
    height: 100%;
    position: relative;
    width: 100%;
  }
</style>

import { readable, writable } from 'svelte/store';

const lightColor = '#fff8e9';
const darkColor = '#181425';

export const themes = readable([
	{
		theme: 'theme-pinkretro',
		label: 'Commodore PET (Cyberpunk)',
		author: 'Lorenzo Herrera',
		url: 'https://unsplash.com/@lorenzoherrera',
		color: '#bf0f82',
		colorDark: '#a8046f',
		fontColor: lightColor,
		background: '../img/bg/lorenzo-herrera-p0j-mE6mGo4-unsplash.jpg',
	},
	{
		theme: 'theme-commodore',
		label: 'Commodore PET',
		author: 'Lorenzo Herrera',
		url: 'https://unsplash.com/@lorenzoherrera',
		color: '#ea6633',
		colorDark: '#d55220',
		fontColor: lightColor,
		background: '../img/bg/commodorepet.jpg',
	},
	{
		theme: 'theme-fall',
		label: 'Autumn Leaves',
		author: 'Lyndon Li',
		url: 'https://unsplash.com/@dynastywind',
		color: '#d03221',
		colorDark: '#bd2010',
		fontColor: lightColor,
		background: '../img/bg/lyndon-li-zrT1tjnxJKQ-unsplash.jpg',
	},
	{
		theme: 'theme-pink',
		label: 'Sakura Tree',
		author: 'AJ',
		url: 'https://unsplash.com/@ajny',
		color: 'pink',
		colorDark: '#f99daf',
		fontColor: darkColor,
		background: '../img/bg/sakura-bg.jpg',
	},
	{
		theme: 'theme-winter',
		label: 'Winter',
		author: 'Mike Kotsch',
		url: 'https://unsplash.com/@mike',
		color: '#4b5d65',
		colorDark: '#3d5059',
		fontColor: lightColor,
		background: '../img/bg/mike-kotsch-9wTWFyInJ4Y-unsplash.jpg',
	},
	{
		theme: 'theme-lightblue',
		label: 'Blue Sky',
		author: 'Jonathan Daniels',
		url: 'https://unsplash.com/@dear_jondog',
		color: '#c0eaff',
		colorDark: '#81c6e8',
		fontColor: darkColor,
		background: '../img/bg/jonathan-daniels-78Fr6nZRDIc-unsplash.jpg',
	},
]);

export const theme = writable('theme-pinkretro');

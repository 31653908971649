<script>
  import { createEventDispatcher, onMount } from "svelte";
  import ButtonRound from "../UI/ButtonRound.svelte";
  import modals from "../Helpers/modal-store";

  const dispatch = createEventDispatcher();

  export let contentData = null;

  contentData;

  let currentWindowKey = "";

  function onTurnOff() {
    dispatch("turnoff", null);
  }

  function onRestart() {
    dispatch("restart", null);
  }

  function onCancel() {
    modals.closeModal(currentWindowKey);
  }

  function getWindowKey() {
    let currentWindow = $modals.filter((element) => element.id == "turnoff");
    currentWindowKey = currentWindow[0].key;
  }

  onMount(() => {
    getWindowKey();
  });
</script>

<div class="center">
  <div class="wrapper">
    <div class="button-wrapper">
      <ButtonRound on:click={onTurnOff}>
        <i class="fas fa-power-off fa-fw" />
      </ButtonRound>
      <div class="button-label">Turn off</div>
    </div>
    <div class="button-wrapper">
      <ButtonRound on:click={onRestart}>
        <i class="fas fa-undo-alt fa-fw" />
      </ButtonRound>
      <div class="button-label">Restart</div>
    </div>
    <div class="button-wrapper">
      <ButtonRound on:click={onCancel}>
        <i class="fas fa-times fa-fw" />
      </ButtonRound>
      <div class="button-label">Cancel</div>
    </div>
  </div>
</div>

<style>
  i {
    padding: 7px 0;
  }

  .center {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
  }

  .button-wrapper i {
    font-size: 2em;
  }

  .button-label {
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    font-size: 0.8em;
  }
</style>

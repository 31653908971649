<script>
  import { createEventDispatcher } from "svelte";
  import WebXpCard from "../../../UI/WebXPCard.svelte";

  export let refresh = null;
  export let src = null;

  src;
  refresh;
  const dispatch = createEventDispatcher();

  function goToPage(page) {
    dispatch("goTo", page);
  }
</script>

<div class="container">
  <div class="banner-wrapper round-border">
    <div class="banner-content round-border">
      <h2>Web Experiments</h2>
      <h5>Here is a collection of a few frontend projects I created.</h5>
      <h6>Click on any project to know more!</h6>
    </div>
  </div>

  <div class="cards-wrapper">
    <WebXpCard
      image="signup-cards.png"
      tags={["Svelte"]}
      on:click={() => goToPage("page_webxp_signup")}
    >
      A simple form using a unique card interaction.
    </WebXpCard>
    <WebXpCard
      image="github-explorer.png"
      tags={["React", "Bootstrap"]}
      on:click={() => goToPage("page_webxp_github_explorer")}
    >
      An app consuming the Github API.
    </WebXpCard>
    <WebXpCard
      image="blog.png"
      tags={["Wordpress"]}
      on:click={() => goToPage("page_blog")}
    >
      The blog present here in the browser was made in Wordpress with a theme
      built from scratch.
    </WebXpCard>
    <WebXpCard
      image="timedashboard.png"
      tags={["HTML", "CSS"]}
      on:click={() => goToPage("page_webxp_time_dashboard")}
    >
      A challenge from Frontend Mentor. Just the basic layout with no
      functionality (yet).
    </WebXpCard>
  </div>
</div>

<style>
  .cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    grid-template-rows: auto;
    margin: 30px 0;
  }

  @media (max-width: 767px) {
    .cards-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }

  .banner-content {
    background-color: #fff8e9;
    border-radius: 9999px;
    padding: 20px 30px;
    width: max-content;
    text-align: center;
  }
  .banner-wrapper {
    margin: 20px 0;
    width: 100%;
    padding: 2em 1em;
    display: flex;
    justify-content: center;

    background-color: #fff8e9;
    background-image: linear-gradient(
        30deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        150deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        30deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        150deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        60deg,
        var(--theme-color-dark) 25%,
        transparent 25.5%,
        transparent 75%,
        var(--theme-color-dark) 75%,
        var(--theme-color-dark)
      ),
      linear-gradient(
        60deg,
        var(--theme-color-dark) 25%,
        transparent 25.5%,
        transparent 75%,
        var(--theme-color-dark) 75%,
        var(--theme-color-dark)
      );
    background-size: 80px 140px;
    background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
  }
</style>

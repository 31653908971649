<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import ButtonRound from "../../../UI/ButtonRound.svelte";

  export let src = null;
  export let refresh = null;
  let isLoading = true;
  let iframe = null;

  $: onRefresh(refresh);

  refresh;

  function preloader() {
    iframe.addEventListener("load", function () {
      isLoading = false;
    });
  }

  onMount(() => {
    iframe = document.querySelector("iframe");
    preloader();
  });

  function onRefresh(ref) {
    isLoading = true;
    if (ref !== null) {
      jQuery("iframe").attr("src", jQuery("iframe").attr("src"));
      preloader();
    }
  }
</script>

<div class="content">
  {#if isLoading}
    <div class="overlay" out:fade={{ duration: 200 }}>
      <i class="fa-solid fa-circle-notch fa-spin" />
    </div>
  {/if}
  <iframe seamless title="Web Experiment" {src} frameborder="0" id="i_frame" />
  <div class="redirect-button-container">
    <ButtonRound circle={true} on:click={() => window.open(src, "_blank")}>
      <i class="fa-solid fa-arrow-up-right-from-square" />
    </ButtonRound>
  </div>
</div>

<style>
  .content {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: relative;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .overlay {
    background-color: rgba(256, 256, 256, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay i {
    color: var(--theme-color);
    font-size: 3em;
  }

  .redirect-button-container {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }
</style>
